<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-icon
          large
        >
          mdi-history
        </v-icon>
        Port Down Alarm
        <!-- <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        /> -->
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="oltInterfaceOperStatusDownAlarms"
        :search.sync="search"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions : [10,50,100]}"
        :loading="loading"
        loading-text="Loading..."
        :server-items-length="totalOltInterfaceOperStatusDownAlarms"
        class="elevation-1"
      >
        <template v-slot:item.status="{ item }">
          <span>
            <v-icon
              large
              :color="setStatusIcon(item.recoveryTime).color"
            >
              {{ setStatusIcon(item.recoveryTime).name }}
            </v-icon>
          </span>
        </template>
        <template v-slot:item.raisedTime="{ item }">
          <span>
            {{ formatDate(item.raisedTime) }}
          </span>
        </template>
        <template v-slot:item.recoveryTime="{ item }">
          <span>
            {{ formatDate(item.recoveryTime) }}
          </span>
        </template>
        <template v-slot:item.duration="{ item }">
          <span>
            {{ calDuration(item.raisedTime, item.recoveryTime) }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

moment.locale('th')

export default {
  name: 'OltInterfaceOperStatusDownAlarmsList',
  props: {

  },
  data() {
    return {
      search: '',
      totalOltInterfaceOperStatusDownAlarms: 0,
      options: {},
      oltInterfaceOperStatusDownAlarms: [],
      loading: false,
      headers: [
        {
          text: 'Status',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Name',
          value: 'oltInterfaceTable.olt.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Project',
          value: 'oltInterfaceTable.olt.oltProject.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'IP Address',
          value: 'oltInterfaceTable.olt.ip',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Brand',
          value: 'oltInterfaceTable.olt.oltType.oltBrand.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Type',
          value: 'oltInterfaceTable.olt.oltType.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Rack',
          value: 'oltInterfaceTable.ifPosition.rack',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Shelf',
          value: 'oltInterfaceTable.ifPosition.shelf',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Slot',
          value: 'oltInterfaceTable.ifPosition.slot',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Port',
          value: 'oltInterfaceTable.ifPosition.port',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Sector',
          value: 'oltInterfaceTable.olt.officeSector.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Raised Time',
          value: 'raisedTime',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Recovery Time',
          value: 'recoveryTime',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Duration',
          value: 'duration',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
    search: {
      handler() {
        this.getDataFromAPI()
      },
      deep: true,
    },
  },
  created() {

  },
  methods: {
    setStatusIcon(recoveryTime) {
      const icon = {}
      if ((recoveryTime === null)) {
        icon.name = 'mdi-alert-circle'
        icon.color = 'error'
      } else {
        icon.name = 'mdi-check-circle'
        icon.color = 'success'
      }
      return icon
    },
    calSysUpTime(upTime) {
      const uptimeSecond = upTime / 100
      const day = Math.floor(uptimeSecond / (86400))
      const hour = Math.floor((uptimeSecond % 86400) / 3600)
      const minute = Math.floor((uptimeSecond % 3600) / 60)
      const upTimeText = ` ${day} วัน ${hour} ชั่วโมง ${minute} นาที `
      return upTimeText
    },
    formatDate(date) {
      if (date !== null) {
        return `${moment(date).format('LL')} ${moment(date).format('LTS')}`
      }
      return null
    },
    calDuration(raisedTime, recoveryTime) {
      if ((raisedTime !== null) && (recoveryTime !== null)) {
        const startTime = moment(raisedTime)
        const endTime = moment(recoveryTime)

        const diff = endTime.diff(startTime)
        const diffDuration = moment.duration(diff)

        let durationText = ''
        durationText += diffDuration.days() === 0 ? '' : `${diffDuration.days()} วัน `
        durationText += diffDuration.hours() === 0 ? '' : `${diffDuration.hours()} ชั่วโมง `
        durationText += diffDuration.minutes() === 0 ? '' : `${diffDuration.minutes()} นาที `
        durationText += diffDuration.seconds() === 0 ? '' : `${diffDuration.seconds()} วินาที `

        return durationText
      }
      return null
    },
    getDataFromAPI() {
      this.loading = true
      const queryParams = {
        params: {
          search: this.search,
          page: this.options.page,
          limit: this.options.itemsPerPage,
        },
      }
      axios.get('/oltInterfaceOperStatusDownAlarm', queryParams)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.oltInterfaceOperStatusDownAlarms = response.data.docs
          this.totalOltInterfaceOperStatusDownAlarms = response.data.totalDocs
          this.loading = false
        })
        .catch((e) => {
          this.errors.push(e)
          this.loading = false
        })
    },
  },
}


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
